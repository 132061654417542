import _fs from "fs";
import _windows from "./windows.js";
import _mode from "./mode.js";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;
var fs = _fs;
var core;

if (process.platform === "win32" || _global.TESTING_WINDOWS) {
  core = _windows;
} else {
  core = _mode;
}

exports = isexe;
isexe.sync = sync;

function isexe(path, options, cb) {
  if (typeof options === "function") {
    cb = options;
    options = {};
  }

  if (!cb) {
    if (typeof Promise !== "function") {
      throw new TypeError("callback not provided");
    }

    return new Promise(function (resolve, reject) {
      isexe(path, options || {}, function (er, is) {
        if (er) {
          reject(er);
        } else {
          resolve(is);
        }
      });
    });
  }

  core(path, options || {}, function (er, is) {
    // ignore EACCES because that just means we aren't allowed to run it
    if (er) {
      if (er.code === "EACCES" || options && options.ignoreErrors) {
        er = null;
        is = false;
      }
    }

    cb(er, is);
  });
}

function sync(path, options) {
  // my kingdom for a filtered catch
  try {
    return core.sync(path, options || {});
  } catch (er) {
    if (options && options.ignoreErrors || er.code === "EACCES") {
      return false;
    } else {
      throw er;
    }
  }
}

export default exports;